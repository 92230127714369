/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Fragment } from 'react';
import cx from 'classnames';
import { SmartLink as Link } from '../../../utils/smartLink';
import azCommonStyles from '../../../theme/globals.module.scss';
import { Button } from '@/components/Button';
import { Label } from '@/features/i18n';
import PriceComponent from '../../AZCustomComponent/Price';
import { FULFILLMENT_METHODS } from '../../../constants/fulfillmentConstants';
import { cartConstants } from '../../../constants/cart';
import styles from './styles.module.scss';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { pageTypeSiteSection } from '@/utils/analytics/dataLayer/pageTypeSiteSection';
import type { PageName } from '@/types/analytics';
import FreeShippingNote from '@/components/AZCustomComponent/FreeShippingNote';
import { useFeatureFlag } from '@/features/globalConfig';
import { useHeaderData } from '@/features/header/api/getHeader';

const trackItemsClickMinicart = () => {
  clickTrack({
    eventType: 'itemsInYourMiniCart',
  });
};

type Props = {
  cartUrl: () => void;
  totalData: any;
  itemInfo: any;
  total: number;
  pageName: PageName;
};

const MiniCartCheckOut = (props: Props) => {
  const { totalData, itemInfo, pageName } = props;
  const { data: headerData, isRefetching: isHeaderRefetching } = useHeaderData();
  const orderDetails = headerData?.miniCartMap?.orderDetails;
  const itemCountValue = totalData?.itemCount ?? 0;
  const isOnlineOrder = itemInfo.orderType === cartConstants.ONLINEORDER;
  const dealPrice = (totalData?.orderSubTotal ?? 0).toFixed(2).toString().split('.');
  const freeShippingMargin = orderDetails?.orders?.[0].freeShippingMargin;
  const showFreeShipMsg = orderDetails?.orders?.[0].showFreeShipMsg;

  const IS_MINI_CART_FREE_SHIP_NOTE_ENABLED =
    useFeatureFlag('IS_MINI_CART_FREE_SHIP_NOTE_ENABLED') === 'true';
  const isShowFreeShippingNote =
    IS_MINI_CART_FREE_SHIP_NOTE_ENABLED && isOnlineOrder && showFreeShipMsg && !isHeaderRefetching;
  /**Analytics Start */

  pageTypeSiteSection(pageName);
  /**Analytics End */

  const maxQuantityCheck = () => {
    const availabilityInfo = itemInfo?.availabilityInfo;
    let maxQuantity = availabilityInfo?.sthQuantity ?? 0;

    if (itemInfo.orderType === cartConstants.STOREORDER) {
      maxQuantity =
        availabilityInfo.find(
          (fulfillmentOption: any) => fulfillmentOption.groupId === FULFILLMENT_METHODS.STOREORDER
        )?.maxAvailableQty ?? 0;
    }

    return maxQuantity;
  };

  const renderItemText =
    itemCountValue === 1 ? (
      <Label label="label_confirmation_Header_YouHave1ItemInYourCart" />
    ) : (
      <Label label="label_confirmation_Header_itemsInYourCart" />
    );
  return (
    <Fragment>
      <div className={styles.miniCartPart}>
        <Link
          to="/cart"
          className={cx(azCommonStyles['az-overline'], styles.cartCountData)}
          onClick={trackItemsClickMinicart}
          rel="nofollow"
        >
          <Label label="label_confirmation_Header_youHave" /> {itemCountValue} {renderItemText}
        </Link>
      </div>
      <div className={styles.cartTotalAndCheckoutBtn}>
        <h2 className={styles.cartTotal}>
          <div className={azCommonStyles['az-title-5-medium']}>
            <Label label="label_confirmation_summary_CartTotal" />
            {':'}
          </div>
          <PriceComponent priceType="primary" dealPrice={dealPrice} enableStrikeThru={false} />
        </h2>
        {isShowFreeShippingNote && (
          <div className={styles.freeShippingNote}>
            <FreeShippingNote freeShippingMargin={freeShippingMargin} />
          </div>
        )}
        <Button
          variant="super"
          type="submit"
          fullWidth
          disabled={!isOnlineOrder && parseInt(props.total.toString()) > maxQuantityCheck()}
          onClick={() => {
            props.cartUrl();
          }}
          data-testid="view-cart-and-checkout-button"
        >
          <Label label="button_confirmation_summary_ViewCartAndCheckout" />
        </Button>
      </div>
    </Fragment>
  );
};

export default MiniCartCheckOut;
