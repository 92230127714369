/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import CustomModal from '@/components/Modal';
import Title from '../../AZCustomComponent/Title';
import { Label } from '@/features/i18n';
import MiniCartModalContent from './MiniCartModalContent';
import styles from './styles.module.scss';
import type { PageName } from '@/types/analytics';

type Props = {
  isModalOpen: boolean;
  closeStoreModal: () => void;
  successData: any;
  image: string;
  productDetails: any;
  cartUrl: () => void;
  productTitle: string;
  pageName: PageName;
};

export const MiniCartModal = (props: Props) => {
  return (
    <CustomModal
      isOpen={props.isModalOpen}
      desktopSize="large"
      handleClose={props.closeStoreModal}
      showCloseButton
      noGap
      heading={
        <Title
          firstText={
            <>
              1 <Label label="label_cart_promo_Item" />
            </>
          }
          firstTextClass={`${styles.miniCartTitleText} ${styles.miniCartItemTitle}`}
          secondTextClass={styles.miniCartTitleText}
          secondText={<Label label="label_confirmation_Header_AddedToCart" />}
          variant="xs"
          swapStyle
          headerType="div"
          stylesClass={styles.minicartHeader}
          isSameTitleTag
          overRuleH1="h1"
          inline
        />
      }
      body={
        <MiniCartModalContent
          successData={props.successData}
          image={props.image}
          productDetails={props.productDetails}
          cartUrl={props.cartUrl}
          productTitle={props.productTitle}
          pageName={props.pageName}
        />
      }
      disableEscapeKeyDown={false}
      disableBackdropClick={false}
    />
  );
};
