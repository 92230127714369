/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { ItemQohErrorMessage, MergedFulfillmentOption } from '@/utils/itemHelpers/types';

export function createFulfillmentAvailableAtSelectedQtyMessage(
  inputFulfillmentOptions: MergedFulfillmentOption[],
  itemQohErrorMessage: ItemQohErrorMessage
): string {
  const errorString = inputFulfillmentOptions
    .sort((a, b) => b.fulfillmentTypeId - a.fulfillmentTypeId)
    .map((option) => {
      const matchedOption = itemQohErrorMessage.optionsWithError.find(
        (e) => e === option.fulfillmentTypeId
      );

      if (matchedOption) {
        return itemQohErrorMessage.errorMessage;
      } else {
        return '';
      }
    })
    .join(':');
  return errorString;
}
