/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const shelfPageVariables = {
  pageBreadCrumb: [] as Array<any>,
  categoryLevel1: '',
  categoryLevel2: '',
  categoryLevel3: '',
  selectedFilterValue: '',
  eventType: '',
  productFilterSelection: '',
  productPart: [] as Array<any>,
  productIdList: [] as Array<any>,
  productLineCodeList: [] as Array<any>,
  productBrandList: [] as Array<any>,
  originalPartTypeIdList: [] as Array<any>,
  productRepositoryIdList: [] as Array<any>,
  bopusList: [] as Array<any>,
  sthList: [] as Array<any>,
  nddEligibleList: [] as Array<any>,
  dealFlagList: [] as Array<any>,
  productPrice: [] as Array<any>,
  productCorePriceList: [] as Array<any>,
  productNotAvailableList: [] as Array<any>,
  resultsPerPage: '',
  resultCount: '',
  displayViewType: '',
  pageDepth: '',
  nddAfterCutOffFlag: [] as Array<any>,
  nddEligibleProductsCount: [] as Array<any>,
  badgesInfo: [] as Array<any>,
  productGuideSpcs: '',
  nullCartridgeRecommendations: '',
  nullCartridgeSelected: '',
};
