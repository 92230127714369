/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { FulfillmentOptionUpdated } from '../types';
import type { FulfillmentOption } from '@/types/reduxStore/skuDetails';

export function calculateNewFulfillmentProps(
  inputFulfillmentOptions: FulfillmentOption[],
  itemQuantity: number
): FulfillmentOptionUpdated[] {
  const fulfillmentAvailabilityAtSelectedQtyList = inputFulfillmentOptions.reduce(
    (acc: Record<string | number, FulfillmentOptionUpdated>, curr: FulfillmentOption) => {
      const availableAtSelectedQuantity = curr.availableQty >= itemQuantity;
      acc[curr.fulfillmentTypeId] = {
        availableAtSelectedQuantity,
        ...curr,
      };
      return acc;
    },
    {}
  );

  return Object.values(fulfillmentAvailabilityAtSelectedQtyList);
}
