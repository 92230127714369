/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { FulfillmentGroup, FulfillmentOption } from '@/types/reduxStore/skuDetails';

export function getItemFulfillmentoptionsFromGroups(
  fulfillmentGroups: Array<FulfillmentGroup | undefined> | undefined
): FulfillmentOption[] {
  const fulfillmentOptions: FulfillmentOption[] = [];
  fulfillmentGroups?.forEach((fulOptGroup) => {
    fulOptGroup?.groupFulfillmentOptions.map((fulOpt) => {
      fulfillmentOptions.push(fulOpt);
    });
  });
  return fulfillmentOptions;
}
