/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect, useState } from 'react';
import cx from 'classnames';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { Grid } from '../../Grid';
import CommonCounterPartV2 from '../CommonCounterPart/CommonCounterPartV2';
import { shelfPageVariables } from '../../../constants/shelfPageVariables';
import { Label } from '@/features/i18n';
import { useLabel } from '@/hooks/useLabels';
import PriceComponent from '../../AZCustomComponent/Price';
import azCommonStyles from '../../../theme/globals.module.scss';
import { countryCodes } from '@/constants/locale';
import { CertonaBlock } from '@/features/certona';
import { useGetCertonaRecommendations } from '@/features/certona/api/useGetCertonaRecommendations';
import { certonaPageType } from '@/constants/certonaPageType';
import ClearanceBadgeWrapper from '../../AZCustomComponent/ClearanceBadge';
import styles from './styles.module.scss';
import modalStyles from '@/components/Dialog/Modal/Modal.module.scss';
import MiniCartCheckout from './MiniCartCheckOut';
import InfoToolTip from '../../AZCustomComponent/InfoToolTip';
import { useAnalytics } from '@/utils/analytics/useAnalytics';
import { pageTypeSiteSection } from '@/utils/analytics/dataLayer/pageTypeSiteSection';
import type { PageName } from '@/types/analytics';
import { analyticsMiniCartPageVariables } from '@/utils/analytics/dataLayer/analyticsMiniCartPageVariables';
import { resetAnalyticsDatalayer } from '@/utils/analytics/dataLayer/resetAnalyticsDatalayer';
import { badges } from '@/constants/analytics/badges';
import { useLocale } from '@/hooks/useLocale';
import { useHeaderData } from '@/features/header/api/getHeader';
import { LineItemFromState } from '@/types/reduxStore/orders';
import { ProductSkuDetails } from '@/features/product';
import { useAvailabilityInfoData } from '@/features/orders/api/getAvailabilityInfo';
import { getAllAvailableQuantitiesForSku } from '@/utils/getFulfillmentOption';
import { formatPrice } from '@/utils/validator/formatPrice';

const minicartVariables = {
  categoryLevel1: '',
  categoryLevel2: '',
  categoryLevel3: '',
  productPart: '',
  productId: '',
  productLineCode: '',
  productBrand: '',
  productPartApplicationIdentifier: '',
  productPartTypeId: '',
  bopus: '',
  sth: '',
  nddEligible: '',
  badgesInfo: '',
  nddAfterCutOffFlag: '',
  productPrice: '',
  dealFlag: '',
  productRequestedQuantity: '',
  productAvailableQuantity: '',
  productFullfillmentType: '',
  fulfillmentAvailabilityList: '',
  fulfillmentAvailableAtSelectedQtyList: '',
};

const analyticsPDPVariables = {
  productPart: '',
  productId: '',
  productLineCode: '',
  productBrand: '',
  dealFlag: '',
  productPrice: '',
  bopus: '',
  nddAfterCutOffFlag: '',
  vdp: '',
  productCorePriceList: '',
  productNotAvailableList: '',
  productPartApplicationIdentifier: '',
  originalPartTypeIdList: '',
  productPartTypeId: '',
  productRepositoryIdList: '',
  categoryLevel1: '',
  categoryLevel2: '',
  categoryLevel3: '',
  fulfillmentAvailabilityList: '',
  nddMarket: '',
  nddHub: '',
  nddStore: '',
};

const certonaMinicartObj = {
  '@type': 'MiniCart_Certona',
  containerID: 'addtocart1_rr',
  desktop: true,
  mobile: true,
  name: 'Certona Content Block',
  tablet: true,
  themeCode: 'white',
};

type Props = {
  successData: any;
  image: string;
  productDetails: {
    productName: {
      productBrand: string;
      productPart: string | undefined;
      quickNote: string | undefined;
    };
    productAvailabilityInfo: ProductSkuDetails | undefined;
  };
  cartUrl: () => void;
  productTitle: string;
  pageName: PageName;
};

const MiniCartModalContent = (props: Props) => {
  const locale = useLocale();
  const hasSetupAnalytics = useAnalytics();

  const { productDetails, successData, productTitle, pageName } = props;

  const cartWhatsItLabel = useLabel('label_cart_lineItem_WhatIsIt');
  const cartCoreDetail = useLabel('pagecontent_cart_corePopup_CoreDetails');
  const { data: headerData } = useHeaderData();
  const miniCartData = headerData?.miniCartMap;

  const [total, setTotal] = useState(successData?.quantity ?? 1);
  const [counterData, setCounterData] = useState({
    itemQuantity: successData?.quantity,
  });

  const { data: availabilityInfoData, refetch: refetchAvailabilityInfo } =
    useAvailabilityInfoData();

  const vehicleId = headerData?.vehicleMap?.catalogVehicleId || '';
  const skuId = productDetails?.productAvailabilityInfo?.skuPricingAndAvailability?.skuId;
  const corePriceValue =
    productDetails?.productAvailabilityInfo?.skuPricingAndAvailability?.corePrice ?? 0;
  const quickNote = productDetails?.productName?.quickNote ?? '';

  useEffect(() => {
    if (successData) {
      const newLocal = {
        itemQuantity: successData?.quantity ?? 1,
        unitAmount:
          productDetails?.productAvailabilityInfo?.skuPricingAndAvailability?.retailPrice ?? 0,
        lineItemId: successData.commerceItemId,
        eligibleForNextDay:
          productDetails?.productAvailabilityInfo?.aZBadgesFlagVO?.eligibleForNextDay ?? false,
        orderType: successData.orderType,
      };
      setCounterData(newLocal);
    }

    pageTypeSiteSection(pageName);
    resetAnalyticsDatalayer(shelfPageVariables);
    resetAnalyticsDatalayer(analyticsPDPVariables);
    window.certonaNotCalled = true;
    return resetAnalyticsDatalayer(minicartVariables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const productAvailabilityInfo = productDetails?.productAvailabilityInfo;

    if (hasSetupAnalytics) {
      const dealAvailable = productAvailabilityInfo?.aZBadgesFlagVO?.dealAvailable ?? false;
      const eligibleForNextDay =
        productAvailabilityInfo?.aZBadgesFlagVO?.eligibleForNextDay ?? false;
      const productLineCode = productAvailabilityInfo?.skuPricingAndAvailability?.productLine ?? '';
      const dealFlag = productAvailabilityInfo?.skuPricingAndAvailability?.dealAvailable ?? false;
      let badgesInfo = '';

      if (dealAvailable && eligibleForNextDay) {
        badgesInfo = `${badges.deal} | ${badges.nextDayDelivery}`;
      } else if (dealAvailable) {
        badgesInfo = badges.deal;
      } else if (eligibleForNextDay) {
        badgesInfo = badges.nextDayDelivery;
      }

      analyticsMiniCartPageVariables(
        { badgesInfo, productLineCode, dealFlag },
        successData?.availabilityInfo?.skuId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const certonaRecommendations = useGetCertonaRecommendations(
    {
      certonaType: certonaPageType.cartAdd,
      skuIds: skuId,
      vehicleID: vehicleId,
    },
    [skuId, vehicleId, isDesktop],
    isDesktop
  );

  const quantityChange = (newQuantityVal: number) => {
    if (newQuantityVal) {
      setTotal(newQuantityVal);
    }
  };

  useEffect(() => {
    refetchAvailabilityInfo();
  }, [refetchAvailabilityInfo]);

  const findMiniCartItemBySkuId = (skuId: string | undefined) => {
    const orderDetails = miniCartData?.orderDetails;
    const orders = orderDetails?.orders;

    if (!orders || !skuId) {
      return null;
    }

    let foundLineItem: LineItemFromState | undefined;
    orders.find((order) => {
      order.shipmentInfoList.find((shipment) => {
        shipment.lineItemList.find((lineItem) => {
          if (lineItem.productInfo.skuId === skuId) {
            foundLineItem = lineItem;
            return true;
          }
        });

        if (foundLineItem) {
          return true;
        }
      });

      if (foundLineItem) {
        return true;
      }
    });
    return foundLineItem;
  };

  const miniCartItem = findMiniCartItemBySkuId(skuId);
  const isDiscountedStrikeThroughPrice = miniCartItem?.lineItemPriceInfo.discounted ?? false;

  const renderPricePart = () => {
    const discounted = miniCartItem?.lineItemPriceInfo?.discounted;
    const retailPrice = miniCartItem?.lineItemPriceInfo?.retailPrice;
    const dealAppliedAmount = miniCartItem?.lineItemPriceInfo?.dealAppliedAmount;
    const discountedPrice =
      retailPrice && dealAppliedAmount ? retailPrice - dealAppliedAmount : null;
    let priceComponent = null;

    if (discounted && discountedPrice) {
      priceComponent = (
        <PriceComponent
          priceType="secondary"
          wasDealPrice={retailPrice?.toFixed(2).split('.')}
          dealPrice={discountedPrice?.toFixed(2).split('.')}
          corePriceValue={corePriceValue}
          enableStrikeThru={false}
        />
      );
    } else if (retailPrice) {
      priceComponent = (
        <PriceComponent
          wrapperClassName={
            miniCartItem?.productInfo?.clearanceItem ? styles.priceComponentWrapper : ''
          }
          priceType="secondary"
          dealPrice={retailPrice?.toFixed(2).split('.')}
          corePriceValue={corePriceValue}
          enableStrikeThru={false}
        />
      );
    }

    return (
      <>
        {priceComponent}
        {miniCartItem?.productInfo?.clearanceItem && (
          <div className={styles.clearanceWrapper}>
            <ClearanceBadgeWrapper />
          </div>
        )}
      </>
    );
  };

  const renderImage = () => {
    const { image } = props;
    const productName = productDetails?.productName;
    const imageAlt = `${productName?.productBrand ?? ''} ${productName?.productPart ?? ''}`;
    const imageSrc = image && image;
    return <img className={styles.thumbnail} src={imageSrc} alt={imageAlt} />;
  };

  const renderCartCommonCounterPart = () => {
    const skuId = productDetails?.productAvailabilityInfo?.skuPricingAndAvailability?.skuId;
    const productId = productDetails?.productAvailabilityInfo?.skuPricingAndAvailability?.productId;
    const returnValue = null;
    const availabilityInfo = availabilityInfoData?.itemAvailability?.find(
      (availability) => availability.skuId === skuId
    );
    const coreDeposit =
      productDetails?.productAvailabilityInfo?.skuPricingAndAvailability?.corePrice ?? 0;
    const selectedAvailability = availabilityInfo?.fulfillmentGroups.find(
      (fulfillmentGroup) => fulfillmentGroup.selected
    );
    const selectedFultillmentOption = selectedAvailability?.groupFulfillmentOptions?.filter(
      (fulfillmentOption) => {
        return fulfillmentOption.selected;
      }
    )[0];
    const fulfillmentId = selectedFultillmentOption?.fulfillmentTypeId;

    if (counterData) {
      return (
        <CommonCounterPartV2
          className={styles.counter}
          data={{
            ...counterData,
            ...miniCartItem,
            productInfo: {
              skuId,
              productId,
            },
          }}
          fulfillmentOptions={availabilityInfo?.fulfillmentGroups || successData?.availabilityInfo}
          fulFillmentId={fulfillmentId}
          updatedValue={quantityChange}
          coreDeposit={coreDeposit}
          isMini
          isNewMessagingShown={false}
          skuAvailableQuantities={getAllAvailableQuantitiesForSku(
            availabilityInfo?.fulfillmentGroups
          )}
          isDiscountedStrikeThroughPrice={isDiscountedStrikeThroughPrice}
        />
      );
    }

    return returnValue;
  };

  const renderCartCheckout = () => {
    const { cartUrl, successData } = props;
    if (miniCartData && successData) {
      return (
        <MiniCartCheckout
          totalData={miniCartData}
          cartUrl={cartUrl}
          itemInfo={successData}
          total={total}
          pageName={pageName}
        />
      );
    }
  };

  const renderCoreDeposit = () => {
    const corePriceValue =
      productDetails?.productAvailabilityInfo?.skuPricingAndAvailability?.corePrice ?? 0;

    if (corePriceValue > 0) {
      return (
        <div className={cx(azCommonStyles['az-margin-top-5xs'], azCommonStyles['az-caption'])}>
          <Label label="label_confirmation_lineItem_Plus" /> ${formatPrice(total * corePriceValue)}{' '}
          <Label label="label_refundable" />{' '}
          <div>
            <Label label="label_confirmation_lineItem_core" />{' '}
            <InfoToolTip
              classes={{
                icon: styles.coreDepositInfoIcon,
                popper: modalStyles.modalTooltip,
              }}
              header={cartWhatsItLabel}
              body={cartCoreDetail}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className={styles.minicartContent}>
        <div className={styles.minicartContentLeft}>
          <Grid container spacing={0}>
            <Grid item lg={4}>
              {renderImage()}
            </Grid>
            <Grid item lg={8}>
              <span className={cx(azCommonStyles['az-body-1-regular'], styles.productNameSpacing)}>
                {productTitle}
              </span>
              <div className={cx(azCommonStyles['az-body-2-regular'], styles.titleSubPart)}>
                {quickNote}
              </div>
              <div
                className={cx(
                  azCommonStyles['az-padding-top-xs'],
                  azCommonStyles['az-padding-bottom-m'],
                  styles.productDetailPart
                )}
              >
                {renderCartCommonCounterPart()}
                <div
                  className={cx(styles.detailRight, {
                    [styles.priceDetailAlignCenter]: miniCartItem?.productInfo?.clearanceItem,
                  })}
                >
                  {renderPricePart()}
                  {renderCoreDeposit()}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.minicartContentRight}>{renderCartCheckout()}</div>
      </div>
      <CertonaBlock
        className={cx(styles.withPadding, styles.miniCart)}
        contentItem={certonaMinicartObj}
        certonaRecommendations={locale === countryCodes.us ? certonaRecommendations : undefined}
      />
    </>
  );
};

export default MiniCartModalContent;
