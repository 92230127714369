/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

export const resetAnalyticsDatalayer = (pageDataLayer: Record<string, unknown>) => {
  const { utag_data } = window || {};

  if (
    utag_data &&
    Object.keys(utag_data).length &&
    pageDataLayer &&
    Object.keys(pageDataLayer).length
  ) {
    window.utag_data = Object.keys(pageDataLayer).reduce((acc, currentKey) => {
      delete utag_data[currentKey];
      return utag_data;
    }, utag_data);
  }
};
