/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { MergedFulfillmentOption } from '@/utils/itemHelpers/types';

export function createFilteredFulfillmentAvailableAtSelectedQty(
  fulfillmentOptions: MergedFulfillmentOption[],
  optionsWithError: Array<string | number>
): string {
  const fulfillmentTypesUnavailable = fulfillmentOptions
    .sort((a, b) => b.fulfillmentTypeId - a.fulfillmentTypeId)
    .map((option) => {
      const matchedOption = optionsWithError.find((e) => e === option.fulfillmentTypeId);

      if (matchedOption) {
        const booleanToString =
          option.fulfillmentTypeId && option.availableAtSelectedQuantity ? 'true' : 'false';

        if (!option.availableAtSelectedQuantity) {
          return `${option.fulfillmentTypeId}-${booleanToString}`;
        } else {
          return '';
        }
      }
    })
    .join(':');

  return fulfillmentTypesUnavailable;
}
