/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { analyticsDataLayerVariable } from '../analyticsDataLayerVariable';

type MiniCartData = {
  categoryLevel1List?: string[];
  categoryLevel2List?: string[];
  categoryLevel3List?: string[];
  productPartList?: string[];
  productIdList?: string[];
  productLineCode?: string;
  productBrandList?: string[];
  productPartApplicationIdentifierList?: string[];
  productPartTypeIdList?: string[];
  badgesInfo?: string;
  nddAfterCutOffFlag?: string;
  productOriginalPriceList?: string[];
  dealFlag?: boolean;
  productRequestedQuantityList?: string[];
  productAvailableQuantityList?: string[];
  productFullfillmentTypeList?: string[];
  fulfillmentAvailabilityList?: string[];
  bopusFullfillmentAvailabilityList?: string[];
  fulfillmentAvailableAtSelectedQtyList?: string[];
};

export function analyticsMiniCartPageVariables(
  miniCartData: MiniCartData | undefined,
  miniCartproductId: string
) {
  const prodIndex =
    (miniCartData?.productIdList && miniCartData.productIdList.indexOf(miniCartproductId)) ?? -1;

  const data = {
    categoryLevel1: miniCartData?.categoryLevel1List?.[prodIndex],
    categoryLevel2: miniCartData?.categoryLevel2List?.[prodIndex],
    categoryLevel3: miniCartData?.categoryLevel3List?.[prodIndex],
    productPart: miniCartData?.productPartList?.[prodIndex],
    productId: miniCartproductId,
    productLineCode: miniCartData?.productLineCode,
    productBrand: miniCartData?.productBrandList?.[prodIndex],
    productPartApplicationIdentifier:
      miniCartData?.productPartApplicationIdentifierList?.[prodIndex],
    productPartTypeId: miniCartData?.productPartTypeIdList?.[prodIndex],
    badgesInfo: miniCartData?.badgesInfo,
    nddAfterCutOffFlag: miniCartData?.nddAfterCutOffFlag,
    productPrice: miniCartData?.productOriginalPriceList?.[prodIndex],
    dealFlag: miniCartData?.dealFlag,
    productRequestedQuantity: miniCartData?.productRequestedQuantityList?.[prodIndex],
    productAvailableQuantity: miniCartData?.productAvailableQuantityList?.[prodIndex],
    productFullfillmentType: miniCartData?.productFullfillmentTypeList?.[prodIndex],
    fulfillmentAvailabilityList: miniCartData?.fulfillmentAvailabilityList?.[prodIndex],
    bopus: miniCartData?.bopusFullfillmentAvailabilityList?.[prodIndex],
    fulfillmentAvailableAtSelectedQtyList:
      miniCartData?.fulfillmentAvailableAtSelectedQtyList?.[prodIndex],
  };

  analyticsDataLayerVariable(data);
}
